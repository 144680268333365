.mainpage {
    height: 100vh;
    position: relative;
    overflow: hidden;

    h1 {
        position: absolute;
        top: 20%;
        font-size: 10rem;
        text-align: center;
        color: $primary-color-opaque;

        span {
            @include transition-ease(.3s);
            &::selection {
                background: $secondary-color;
            }

            &:hover {
                color: $primary-color;
                @include transition-ease(.3s)
            }
        }

        .orange {
            color: $secondary-color-opaque;
            @include transition-ease(.3s);

            &::selection {
                color: $primary-color;
                background-color: $secondary-color;
            }
            
            &:hover {
                color: $secondary-color;
                @include transition-ease(.3s)
            }
        }

        &::selection {
            background: $secondary-color;
        }
    }
}