@include media-mobile {
    body {
        .mainpage {
            h1 {
                top: 30%;
                font-size: 3rem;
                user-select: none;
            }
        }
    }
}

@include media-laptop {
    body {
        .mainpage {
            h1 {
                font-size: 8rem;
            }
        }
    }
}