@mixin media-mobile {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin media-laptop {
    @media screen and (min-width: 768px) and (max-width: 1600px) {
        @content;
    }
}

@mixin transition-ease($sec) {
    transition: all $sec ease-in-out;
}